<template>
  <backoffice-container>
    <dashboard-layout
      :items="items"
      :english-video="englishVideo"
      :spanish-video="spanishVideo"
      :url="url"
      :steps-title="$t('backoffice.dashboard.build-community')"
      :video-title="$t('backoffice.dashboard.video-title')"
      :video-subtitle="$t('backoffice.dashboard.video-subtitle')"
      :welcome-title="$t('backoffice.welcome')"
    />
  </backoffice-container>
</template>

<script>
import DashboardLayout from '@/views/myself/components/DashboardLayout.vue';
import BackofficeContainer from '@/backoffice/components/BackofficeContainer.vue';

export default {
  components: {
    DashboardLayout,
    BackofficeContainer,
  },
  computed: {
    items() {
      return [
        {
          title: this.$t('backoffice.dashboard.steps.first.title'),
          rest: this.$t('backoffice.dashboard.steps.first.rest', { item: `<a href='${this.url(this.$t('backoffice.dashboard.steps.first.subtitle'))}' class='text-primary font-weight-bolder'>${this.getItem(this.$t('backoffice.dashboard.steps.first.subtitle'))}</a>`}),
          completed: false,
        },
        {
          title: this.$t('backoffice.dashboard.steps.second.title'),
          rest: this.$t('backoffice.dashboard.steps.second.rest', { item: `<a href='${this.url(this.$t('backoffice.dashboard.steps.second.subtitle'))}' class='text-primary font-weight-bolder'>${this.getItem(this.$t('backoffice.dashboard.steps.second.subtitle'))}</a>`}),
          completed: false,
        },
        {
          title: this.$t('backoffice.dashboard.steps.third.title'),
          rest: this.$t('backoffice.dashboard.steps.third.rest', { item: this.getItem(this.$t('backoffice.dashboard.steps.third.subtitle'))}),
          completed: false,
        },
        {
          title: this.$t('backoffice.dashboard.steps.fourth.title'),
          rest: this.$t('backoffice.dashboard.steps.fourth.rest', { item: `<a href='${this.url(this.$t('backoffice.dashboard.steps.fourth.subtitle'))}' class='text-primary font-weight-bolder'>${this.getItem(this.$t('backoffice.dashboard.steps.fourth.subtitle'))}</a>`}),
          completed: false,
        },
        {
          title: this.$t('backoffice.dashboard.steps.fifth.title'),
          rest: this.$t('backoffice.dashboard.steps.fifth.rest', { item: `<a href='${this.url(this.$t('backoffice.dashboard.steps.fifth.subtitle'))}' class='text-primary font-weight-bolder'>${this.getItem(this.$t('backoffice.dashboard.steps.fifth.subtitle'))}</a>`}),
          completed: false,
        },
        {
          title: this.$t('backoffice.dashboard.steps.sixth.title'),
          rest: this.$t('backoffice.dashboard.steps.sixth.rest', { item: `<a href='${this.url(this.$t('backoffice.dashboard.steps.sixth.subtitle'))}' class='text-primary font-weight-bolder'>${this.getItem(this.$t('backoffice.dashboard.steps.sixth.subtitle'))}</a>`}),
          completed: false,
        },
      ];
    },
    englishVideo() {
      return 'https://s3.eu-west-1.amazonaws.com/uploadscdn.nectios.com/videos-full/DuGmdavYjjR6KUk9YpoCDashboard_BO_video_EN.mp4';
    },
    spanishVideo() {
      return 'https://uploadscdn.nectios.com/videos/qw5ejOEZRTbYpLnrI71vVideo_presentacion_BO.mp4';
    },
    community() {
      return this.$store.getters.currentCollective;
    },
  },
  methods: {
    getItem(item) {
      const step = item;
      return step;
    },
    url(item) {
      let subdomain = '';
      if (item === this.$t('backoffice.dashboard.steps.first.subtitle')) {
        subdomain = 'settings';
      }
      if (item === this.$t('backoffice.dashboard.steps.second.subtitle')) {
        subdomain = 'app-store';
      }
      if (item === this.$t('backoffice.dashboard.steps.third.subtitle')) {
        subdomain = 'app-store';
      }
      if (item === this.$t('backoffice.dashboard.steps.fourth.subtitle')) {
        subdomain = 'legal';
      }
      if (item === this.$t('backoffice.dashboard.steps.fifth.subtitle')) {
        subdomain = 'members';
      }
      if (item === this.$t('backoffice.dashboard.steps.sixth.subtitle')) {
        subdomain = 'plans';
      }

      return `${process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace('{slug}', this.community.slug).replace(
        '{subdomain}',
        'app',
      )}/backoffice/${subdomain}`;
    },
  },
};
</script>
